import { startOfDay } from 'date-fns';
import React from 'react';
import { ArrayField, UseFormMethods, FieldError } from 'react-hook-form';
import { RoleDto } from 'server-openapi';
import { DateInput } from '../../../../kit/Forms/DateInput';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { SelectOption, Select } from '../../../../kit/Forms/Select';
import { Grid } from '../../../../kit/Grid';
import { RoleAccessDto, UserDetailsFormValues } from '../UserDetailsPage';

interface IProps {
  field: Partial<ArrayField<RoleAccessDto, 'id'>>;
  index: number;
  form: UseFormMethods<UserDetailsFormValues>;
  roleOptions: SelectOption<RoleDto>[];
  disabled?: boolean;
  roleDisabled?: boolean;
}

export function UserDetailsRoleEntry(props: IProps) {
  const { field, index, form, roleOptions, disabled, roleDisabled } = props;

  return (
    <Grid colsTemplate="2fr 1fr 1fr">
      <div>
          <div>ROLE <span style={{ color: 'red' }}>*</span></div>
          <FormGroup fullWidth error={(form.errors.roleAccess?.[index]?.role as FieldError)?.message}>
          <Select
            fullWidth
            name={`roleAccess.${index}.role`}
            options={roleOptions}
            disabled={roleDisabled || disabled}
            value={roleOptions.find((ro) => ro.value.urn === field.role?.urn)?.value ?? {'No RoleOption for field.role?.urn' : 'No RoleOption for field.role?.urn'}}

            rules={{
              validate: (role: RoleDto) => {
                if (role === undefined || Object.keys(role).length <= 1) {
                  return 'The role field is required';
                }
              },
            }}
          />
        </FormGroup>
      </div>
      <div>
          <div>START DATE <span style={{ color: 'red' }}>*</span></div>
          <FormGroup fullWidth error={form.errors.roleAccess?.[index]?.startDate?.message}>
          <DateInput
            dateTime
            name={`roleAccess.${index}.startDate`}
            value={field.startDate}
            fullWidth
            rules={{
              validate: (startDate: Date) => {
                if (!startDate) {
                  return 'The start date field is required';
                }
                const expiryDate = form.getValues().roleAccess[index].expiryDate;

                if (startDate && expiryDate && startOfDay(startDate) > startOfDay(expiryDate)) {
                  return 'Start date cannot be later than expiry date';
                }
              },
            }}
            disabled={disabled}
          />
        </FormGroup>
      </div>
      <div>
        <div>EXPIRY DATE</div>
        <FormGroup fullWidth error={form.errors.roleAccess?.[index]?.expiryDate?.message}>
          <DateInput
            dateTime
            name={`roleAccess.${index}.expiryDate`}
            value={field.expiryDate}
            fullWidth
            rules={{
              validate: (expiryDate: Date) => {
                const startDate = form.getValues().roleAccess[index].startDate;

                if (expiryDate && startDate && startOfDay(expiryDate) < startOfDay(startDate)) {
                  return 'Expiry date cannot be earlier than start date';
                }
              },
            }}
            disabled={disabled}
          />
        </FormGroup>
      </div>
    </Grid>
  );
}
