/* eslint-disable max-lines */
import itiriri from 'itiriri';
import React, {useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {LoginType, RoleDto, UserInvitationDto, UserOnboardingReq, UserRoleAssignment} from 'server-openapi';
import styled from 'styled-components';
import urljoin from 'url-join';
import {apis} from '../../../../../core/mrs/apis';
import {Button, Variation} from '../../../../../kit/Button';
import {createDataSource, DataTable} from '../../../../../kit/DataTable/DataTable';
import {Dialog} from '../../../../../kit/Dialog';
import {Select, SelectOption} from '../../../../../kit/Forms/Select';
import {AsyncResource, useAsync} from '../../../../../kit/hooks/UseAsync';
import {Layout} from '../../../../../kit/Layout';
import {Intent} from '../../../../../kit/Theme/Theme';
import {toasts} from '../../../../../kit/Toasts/Toaster';
import {ImportUsersDialogContent} from './ImportUsersDialogContent';
import {DateUtils} from '../../../../../core/utils/dateUtils';
import { format, startOfDay, toDate } from 'date-fns';
import { enAU } from 'date-fns/locale';
import _ from 'lodash';
import {RequirePermission} from '../../../../../components/RequirePermission/RequirePermission';
import {TableActionButton} from '../../FacilitySettingsPage';
import {TextInput} from "../../../../../kit/Forms/TextInput";
import {ImportUsersDetail} from "./ImportUsersDetail";
import {useGlobalPermissions, useGroupPermissions} from "../../../../../core/authz/PermissionsProvider";
import moment from 'moment';
import { RiFileExcel2Line } from 'react-icons/ri';
import { ceil } from 'lodash-es';
import { Cell } from 'recharts';

interface IParams {
  facilityGroupId: string;
}
type ActiveFilterType = "Active" | "Inactive" | "All";
function UsersSettingsContent() {
  const facilityGroupId = parseInt(useParams<IParams>().facilityGroupId);
  const [filterActive, setFilterActive] = useState<ActiveFilterType>("Active");
  const [filterRole, setFilterRole] = useState<RoleDto>();
  const [filterLoginType, setFilterLoginType] = useState<LoginType>();
  const [filterEmail, setFilterEmail] = useState<string>();
  const [filterName, setFilterName] = useState<string>();
  const [importUsers, setImportUsers] = useState<UserOnboardingReq[]>([]);

  const [existingUserEntry, setExistingUserEntry] = useState<UserInvitationDto>();
  const [openImportUsersDialog, setOpenImportUsersDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openConfirmDeleteSubjectDialog, setOpenConfirmDeleteSubjectDialog] = useState(false);
  const history = useHistory();
  const newUsersPagePath = `/admin/facility-group/${facilityGroupId}/settings/users/new`;
 const globalPermissions = useGlobalPermissions();
  const goToNewUsersPage = () => {
    history.push(newUsersPagePath);
  };
//const user = useCurrentUser();
//const userUtils = useApiUtils().users;
//const userName = userUtils.getUserFullNameAndRoleFromSubjectId(user.profile.sub)
const canDeleteUsers = globalPermissions.canDeleteUsers;

  const userInvitations = useAsync(async () => {
    return (await apis.users.userGetUserInvitations(facilityGroupId)).data;
  }, []);

  const roles =
    useAsync(async () => {
      return (await apis.roles.roleGetAll(facilityGroupId)).data;
    }).value ?? [];

  const dataSource = createUsersTableData(
    userInvitations,
    roles,
    (user) => {
      setExistingUserEntry(user);
      setOpenConfirmDeleteDialog(true);
    },
  (user) => {
      setExistingUserEntry(user);
          setOpenConfirmDeleteSubjectDialog(true);
      },
    filterActive,
    filterRole,
    filterLoginType,
    filterEmail,
    filterName,
    canDeleteUsers
  );

  function usersSelected(usersToImport: UserOnboardingReq[]) {
      setImportUsers(usersToImport);
      setOpenImportUsersDialog(false);
  }
  function updateCompleted() {
      setImportUsers([]);
      userInvitations.refresh();
  }
  return (importUsers.length > 0 ?
          <ImportUsersDetail usersToImport={importUsers} facilityGroupId={facilityGroupId} roles={roles} updateCompleted={updateCompleted}></ImportUsersDetail>  :

          <>
           <HeaderSection
               roles={roles}
               openImportUsersDialog={() => setOpenImportUsersDialog(true)}
               setFilterActive={setFilterActive}
               setFilterRole={setFilterRole}
               filterActive={filterActive}
               filterRole={filterRole}
               setFilterLoginType={setFilterLoginType}
               filterLoginType={filterLoginType}
               createNewUser={goToNewUsersPage}
               setFilterEmail={setFilterEmail}
               filterEmail={filterEmail}
               setFilterName={setFilterName}
               filterName={filterName}
           />
           <TableContainer>
               <DataTable datasource={dataSource} filters={[]} itemLabel={'users'} />
           </TableContainer>
           <Dialog
               lazy
               open={openImportUsersDialog}
               onRequestClose={() => setOpenImportUsersDialog(false)}
               size="sm"
               secondary
           >
               <ImportUsersDialogContent
                   facilityGroupId={facilityGroupId}
                   roles={roles}
                   usersSelected={usersSelected}
               />
           </Dialog>
           <Dialog
               open={openConfirmDeleteDialog}
               onRequestClose={() => setOpenConfirmDeleteDialog(false)}
               size="sm"
               secondary
           >
               <Layout gap={1}>
                   <h2>
                       Are you sure you want to remove access for {existingUserEntry?.firstName} {existingUserEntry?.familyName}?
                   </h2>
                   <Layout gap={1} horizontal justify="right">
                       <Button onClick={() => setOpenConfirmDeleteDialog(false)} intent={Intent.Secondary}>
                           CANCEL
                       </Button>
                       <Button
                           onClick={async () => {
                               await onRemoveUserEntry(existingUserEntry!, facilityGroupId);
                               setOpenConfirmDeleteDialog(false);
                               userInvitations.refresh();
                           }}
                           intent={Intent.Secondary}
                       >
                           CONFIRM
                       </Button>
                   </Layout>
               </Layout>
           </Dialog>
          <Dialog
              open={openConfirmDeleteSubjectDialog}
              onRequestClose={() => setOpenConfirmDeleteSubjectDialog(false)}
              size="sm"
              secondary
          >
              <Layout gap={1}>
                  <h2>
                      Are you sure you want to remove ALL facility access for {existingUserEntry?.firstName} {existingUserEntry?.familyName}?
                  </h2>
                  <Layout gap={1} horizontal justify="right">
                      <Button onClick={() => setOpenConfirmDeleteSubjectDialog(false)} intent={Intent.Secondary}>
                          CANCEL
                      </Button>
                      <Button
                          onClick={async () => {
                              onDeleteSubject(existingUserEntry!);
                              setOpenConfirmDeleteSubjectDialog(false);
                              userInvitations.refresh();
                          }}
                          intent={Intent.Secondary}
                      >
                          CONFIRM
                      </Button>
                  </Layout>
              </Layout>
          </Dialog>

       </>
    );
}

export function UsersSettings() {
  const groupPermissions = useGroupPermissions();

  return (
    <RequirePermission hasPermission={groupPermissions.canViewFacilityGroupConfigurationIncludingUserManagement ||
                                      groupPermissions.canViewFacilityGroupConfigurationExcludingUserManagement ||
                                      groupPermissions.canAssignRolesExcludingUserManagementPermission}>
      <UsersSettingsContent />
    </RequirePermission>
  );
}

function displayName(inv: UserInvitationDto): string {
    const names: string[] = [];
    if (inv.familyName) {
        names.push(inv.familyName);
    }
    if (inv.firstName) {
        names.push(inv.firstName);
    }
    let result = names.join(', ');
    if (inv.designation) {
        result += ` (${inv.designation})`;
    }
    return result;
}
function isActiveRoleAssignment(roleAssignment: UserRoleAssignment): boolean {
 return !roleAssignment.softDelete && (!roleAssignment.endAt || DateUtils.toDate(roleAssignment.endAt) > new Date());
}
function createUsersTableData(
  userInvitations: AsyncResource<UserInvitationDto[]>,
  roles: RoleDto[],
  onDelete: (user: UserInvitationDto) => void,
  onDeleteSubject:(user: UserInvitationDto) => void,
  filterActive: ActiveFilterType,
  filterRole?: RoleDto,
  filterLoginType?: LoginType,
  filterEmail?: string,
  filterName?: string,
  canDeleteUsers?: boolean
) {
  const tableData = (userInvitations.value ?? [])
    .filter(
      (user) =>
        filterRole === undefined ||
        user.userRoleAssignments
          ?.filter((ura) => !ura.softDelete && (!ura.endAt || DateUtils.toDate(ura.endAt) > new Date()))
          .some((ura) => ura.roleUrn === filterRole.urn!),
    )
    .filter((user) => {
        if (filterActive === "All") {
            return true;
        }
        const numAssignments =  (user.userRoleAssignments?.filter(r => isActiveRoleAssignment(r)).length ?? 0);
        if (filterActive === "Inactive" && numAssignments === 0) {
            return true;
        }
        return (filterActive === "Active" && numAssignments > 0);
    })
    .filter((user) => filterLoginType === undefined || user.loginType === filterLoginType)
    .filter((user) => filterEmail === undefined || user.email?.toUpperCase().includes(filterEmail.toUpperCase()))
    .filter((user) => filterName === undefined || (user.firstName + ' ' + user.familyName).toUpperCase().includes(filterName.toUpperCase()))
    .sort((a, b) => displayName(a).localeCompare(displayName(b)))
    .map((user) => {
      const userRoles = user.userRoleAssignments
        ?.filter((ura) => isActiveRoleAssignment(ura))
        ?.map((ura) => roles.find((r) => r.urn === ura.roleUrn)?.name);

      return {
        col1: (
          <Link to={urljoin('users', user.id!.toString())}>
            <b>
              {displayName(user)}
            </b>
          </Link>
        ),
        col2: (
            <span style={{ width: '100px', textOverflow: 'ellipsis'}}>
                {user.email}
            </span>
        ),
        col3: (
          <Layout horizontal gap={1}>
            {user.status}
          </Layout>
        ),
        col4: user.loginType,
        col5: _.uniq(userRoles).join(', '),
        col6: (user.lastAccessedDateTime===null || user.lastAccessedDateTime===undefined) ? 0 : Date.parse(user.lastAccessedDateTime), //format(Date.parse(user.lastAccessedDateTime), 'Pp', {locale: enAU }),
        col7: (
            <>
          <TableActionButton
            variation={Variation.text}
            disabled={userRoles?.length === 0}
            onClick={() => {
              onDelete(user);
            }}
          >
            End Access
          </TableActionButton>
                {canDeleteUsers &&
                    <TableActionButton
                        variation={Variation.text}
                        onClick={() => {
                            onDeleteSubject(user);
                        }}
                    >
                        Delete
                    </TableActionButton>
                }
            </>
        ),
      };
    });

  return createDataSource(tableData, () => [
    { accessor: (data) => data.col1, Header: 'NAME', width: '150px' },
    { accessor: (data) => data.col2, Header: 'EMAIL', width: '150px'},
    { accessor: (data) => data.col3, Header: 'ACCOUNT STATUS', width: '150px' },
    { accessor: (data) => data.col4, Header: 'LOGIN TYPE', width: '150px'},
    { accessor: (data) => data.col5, Header: 'ROLES', width: '200px' },
    { accessor: (data) => data.col6, Header: 'LAST ACCESSED', width: '200px', sortType: 'number',
        Cell: cell => {
            const row = cell.row.original;
            var value = '';
            if (row.col6!==0) (value = format(parseInt(row.col6.toString()), 'Pp', {locale: enAU }));
            return (<>{value}</>);
        },
    },

    { accessor: (data) => data.col7, Header: 'ACCESS', width: '50px' },
  ]);
}

async function onRemoveUserEntry(existingUserEntry: UserInvitationDto, facilityGroupId: number) {
  await apis.users.userUpdateUserInvitation({
    facilityGroupId: facilityGroupId,
    id: existingUserEntry.id!,
    loginType: existingUserEntry.loginType ?? LoginType.Sso,
    designation: existingUserEntry.designation,
    userRoleAssignments:
      existingUserEntry.userRoleAssignments
        ?.filter((ura) => !ura.softDelete)
        ?.map((ura) => {
          const today = startOfDay(new Date());
          return { ...ura, endAt: DateUtils.fromDate(today) };
        }) ?? [],
  });

  toasts.success(
    `User access for (${existingUserEntry.firstName} ${existingUserEntry.familyName}) has been removed successfully.`,
  );
}
function onDeleteSubject(existingUserEntry: UserInvitationDto) {
    apis.users.userDeleteSubject(existingUserEntry.id).then(() => {
        toasts.success(
            `User access for (${existingUserEntry.firstName} ${existingUserEntry.familyName}) has been removed successfully.`,
        );
    })
    .catch(err => {
        toasts.error("Error removing user access: " + err.message)
        }
    );

}

function HeaderSection(props: {
  setFilterActive: (filterActive: ActiveFilterType) => void;
  filterActive: ActiveFilterType;
  roles: RoleDto[];
  openImportUsersDialog: () => void;
  setFilterRole: (filterRole: RoleDto) => void;
  filterRole?: RoleDto;
  setFilterLoginType: (filterRole: LoginType) => void;
  filterLoginType?: LoginType;
  setFilterEmail: (email: string) => void;
  filterEmail?: string;
  setFilterName: (name: string) => void;
  filterName?: string;
  createNewUser: () => void;
}) {
  const activeOptions = ["Active", "All", "Inactive"].map((r) => ({label: r, value: r} as SelectOption<ActiveFilterType>));
  const roleOptions = itiriri(props.roles)
    .sort((a, b) => a.name!.localeCompare(b.name!))
    .toArray()
    .map((r) => ({ label: r.name, value: r } as SelectOption<RoleDto>));
  const loginTypeOptions = Object.keys(LoginType).map((t) => ({ label: t, value: LoginType[t as keyof typeof LoginType] } as SelectOption<LoginType>));
  const groupPermissions = useGroupPermissions();
  //console.dir(groupPermissions, groupPermissions);
  return (
    <HeaderContainer>
      <FilterContainer>
      <UserTypeFilterContainer gap={0.5}>
          <div className="input-label">USER STATUS</div>
          <Select
              fullWidth
              name="selectActive"
              options={activeOptions}
              value={props.filterActive}
              onChange={(_, v) => {
                  if (v) {
                    props.setFilterActive(v);
                  }
              }}
          />
      </UserTypeFilterContainer>
        <RoleFilterContainer gap={0.5}>
          <div className="input-label">ROLE</div>
          <Select
            fullWidth
            name="selectRole"
            options={roleOptions}
            value={props.filterRole}
            onChange={(_, v) => {
              props.setFilterRole(v);
            }}
          />
        </RoleFilterContainer>
        <LoginTypeFilterContainer gap={0.5}>
          <div className="input-label">LOGIN TYPE</div>
          <Select
            fullWidth
            name="selectLoginType"
            options={loginTypeOptions}
            value={props.filterLoginType}
            onChange={(_, v) => {
              props.setFilterLoginType(v);
            }}
          />
        </LoginTypeFilterContainer>
        <EmailFilterContainer gap={0.5}>
          <div className="input-label">EMAIL</div>
          <TextInput
              fullWidth
              name="emailFilter"
              value={props.filterEmail}
              onChange={(_, v) => {
                  props.setFilterEmail(v);
              }}
          />
        </EmailFilterContainer>
        <NameFilterContainer gap={0.5}>
          <div className="input-label">NAME</div>
          <TextInput
              fullWidth
              name="nameFilter"
              value={props.filterName}
              onChange={(_, v) => {
                  props.setFilterName(v);
              }}
          />
        </NameFilterContainer>
      </FilterContainer>
      {(groupPermissions.canAddAndRevokeUser) ?
      <ButtonActionsContainer>
        <Button onClick={props.createNewUser} intent={Intent.Secondary} variation={Variation.outline}>
          ADD USER
        </Button>
        <Button onClick={props.openImportUsersDialog} intent={Intent.Secondary} variation={Variation.outline}>
          IMPORT USERS
        </Button>
      </ButtonActionsContainer>
      : null }
    </HeaderContainer>
  );
}

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const TableContainer = styled.div`
  margin-top: 30px;
`;

const UserTypeFilterContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  padding: 20px;
  width: 150px;

  & .input-label {
    font-size: 12px;
    font-weight: bold;
  }
`;
const RoleFilterContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  padding: 20px;
  width: 250px;

  & .input-label {
    font-size: 12px;
    font-weight: bold;
  }
`;

const LoginTypeFilterContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  padding: 20px;
  width: 150px;

  & .input-label {
    font-size: 12px;
    font-weight: bold;
  }
`;
const EmailFilterContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  padding: 20px;
  width: 375px;

  & .input-label {
    font-size: 12px;
    font-weight: bold;
  }
`;
const NameFilterContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  padding: 20px;
  width: 375px;

  & .input-label {
    font-size: 12px;
    font-weight: bold;
  }
`;
